import { Download } from '@mui/icons-material'
import { Button } from '@mui/material'
import { json2csv } from 'json-2-csv'
import { KeysList } from 'json-2-csv/lib/types'
import { useTranslation } from 'react-i18next'
import {
  AnimalDtoBreedSizeEnum,
  AnimalDtoColoursEnum,
  AnimalDtoSexCodeEnum,
  MicrochipDto,
  RegistrationDto,
} from '../generated/animare-management-api'
import { Code } from '../generated/codes-app-api'
import useBreeds from '../hooks/useBreeds'
import useCountries from '../hooks/useCountries'
import { DATE_FORMAT_FI, useDate } from '../hooks/useDate'

export type Props = {
  registrations: RegistrationDto[],
  isCurrent: boolean
}

export default function RegistrationsDownload({ registrations, isCurrent }: Props) {
  const { t, i18n } = useTranslation('common')
  const { fetchBreeds } = useBreeds()
  const { fetchCountries } = useCountries()
  const { formatDate } = useDate()

  const { data: breeds, error: breedsError, isPending: isBreedsPending } = fetchBreeds()
  const { data: countries, error: countriesError, isPending: isCountriesPending } = fetchCountries()

  if (isBreedsPending || isCountriesPending) {
    return null
  }

  const breedName = (code: string | undefined) => {
    // if we fail to fetch breeds, return the code by default
    if (breedsError) {
      return code
    }
    if (!code) {
      return ''
    }
    const result = (breeds ?? []).find((b: Code) => b.code === code)
    return result?.labels?.find((label) => label.lang === i18n.language)?.label
  }

  const countryName = (code: string | undefined) => {
    // if we fail to fetch countries, return the code by default
    if (countriesError) {
      return code
    }
    if (!code) {
      return ''
    }
    const result = (countries ?? []).find((b: Code) => b.code === code)
    return result?.labels?.find((label) => label.lang === i18n.language)?.label
  }

  const otherMicrochips = (
    microChip: MicrochipDto | undefined,
    microchips: MicrochipDto[] | undefined,
  ) => {
    const other = microchips
      ?.filter((mc) => mc.microchipNumber !== microChip?.microchipNumber)
      .map((mc) => mc.microchipNumber)

    return other?.join(' ')
  }

  const sizeText = (size: AnimalDtoBreedSizeEnum | undefined) => {
    return size !== undefined ? t(`common.size.${size}`) : size
  }

  const sexText = (sex: AnimalDtoSexCodeEnum | undefined) => {
    return sex !== undefined ? t(`common.sex.${sex}`) : sex
  }

  const colorsText = (colors: AnimalDtoColoursEnum[] | undefined) => {
    return colors?.map((color) => t(`common.color.${color}`)).join(' ')
  }

  const dateFormatFi = (givenDate: Date | undefined) => {
    return givenDate ? formatDate(givenDate, DATE_FORMAT_FI) : undefined
  }

  const downloadCsv = () => {
    const data = registrations.map((r) => {
      return {
        ...r,
        breedCode: breedName(r.animal?.breedCode),
        otherMicrochips: otherMicrochips(r.microchip, r.animal?.microchips),
        sizeCode: sizeText(r.animal?.breedSize),
        sexCode: sexText(r.animal?.sexCode),
        colors: colorsText(r.animal?.colours),
        birthCountry: countryName(r.animal?.birthCountryCode),
        birthDate: dateFormatFi(r.animal?.birthDate),
        deathDate: dateFormatFi(r.animal?.deathDate),
      }
    })

    const keys = [
      {
        field: 'microchip.microchipNumber',
        title: t('animalDetails.field.microchip'),
      },
      {
        field: 'otherMicrochips',
        title: t('renewMicrochip.oldMicrochipTitle'),
      },
      {
        field: 'animal.name',
        title: t('animalDetails.field.name'),
      },
      {
        field: 'breedCode',
        title: t('animalDetails.field.breed'),
      },
      {
        field: 'birthDate',
        title: t('animalDetails.field.birthDate'),
      },
      {
        field: 'deathDate',
        title: t('common.animal.deathDate'),
      },
      {
        field: 'birthCountry',
        title: t('animalDetails.field.birthCountry'),
      },
      {
        field: 'animal.breedDescription',
        title: t('common.animal.description'),
      },
      {
        field: 'sizeCode',
        title: t('common.animal.size'),
      },
      {
        field: 'sexCode',
        title: t('common.animal.sex'),
      },
      {
        field: 'colors',
        title: t('common.animal.color'),
      },
      {
        field: 'animal.kennelName',
        title: t('animalDetails.field.kennelName'),
      },
    ] as KeysList

    const resultsAsCsv = json2csv(data, {
      emptyFieldValue: '',
      keys,
      preventCsvInjection: true,
    })
    const url = URL.createObjectURL(new Blob([resultsAsCsv]))
    const timestamp = new Date().toISOString().substring(0, 19).replaceAll(':', '')
    const filename = isCurrent ? `koirarekisteri-registrations-export-${timestamp}.csv` : `koirarekisteri-past-registrations-export-${timestamp}.csv`

    // We need to create a virtual link element and click it to download the blob
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)

    // download proper
    link.click()

    // cleanup
    if (!!link.parentNode) {
      link.parentNode.removeChild(link)
    }

    URL.revokeObjectURL(url)
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => downloadCsv()}
        startIcon={<Download sx={{ fill: 'primary.main' }} />}
      >
        {isCurrent ? t('monitoring.downloadCurrent') : t('monitoring.downloadPast')}
      </Button>
    </>
  )
}
