import { Box, IconButton, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { enUS, fiFI, svSE } from '@mui/x-data-grid/locales'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DATE_FORMAT_FI, useDate } from '../hooks/useDate'
import useBreeds from '../hooks/useBreeds'

import {
  RegistrationDto,
  AnimalCountryType,
  AnimalLocationType,
} from '../generated/animare-management-api'
import RegistrationsDownload from './RegistrationsDownload'
import SpacerBox from './SpacerBox'

export interface Props {
  possessorUid?: string
  registrations: RegistrationDto[]
  headerSize?: 'h2' | 'h3'
}

const RegistrationsTable = ({ possessorUid, registrations, headerSize = 'h3' }: Props) => {
  const { t, i18n } = useTranslation('common')
  const navigate = useNavigate()
  const { formatDate } = useDate()
  const { fetchBreeds } = useBreeds()
  const locales = () => {
    if (i18n.language === 'fi') {
      return fiFI.components.MuiDataGrid.defaultProps.localeText
    } else if (i18n.language === 'en') {
      return enUS.components.MuiDataGrid.defaultProps.localeText
    } else if (i18n.language === 'sv') {
      return svSE.components.MuiDataGrid.defaultProps.localeText
    }
  }

  const { data: breeds, error: breedsError } = fetchBreeds()

  const breedName = (code: string | undefined): string => {
    if (breedsError || !code) return t('common.breed.unknown')
    const result = (breeds ?? []).find((b) => b.code === code)
    return (
      result?.labels?.find((label) => label.lang === i18n.language)?.label ??
      t('common.breed.unknown')
    )
  }

  function getStatus(registration: RegistrationDto): string {
    return registration.endDate
      ? t('animalsTable.status.ended')
      : !!registration?.animal?.deathDate
      ? t('animalsTable.status.dead')
      : registration.animal?.status?.country.type == AnimalCountryType.Abroad
      ? t('animalsTable.status.abroad')
      : registration?.animal?.status?.location.type == AnimalLocationType.Missing
      ? t('animalsTable.status.missing')
      : ''
  }

  const getEndDate = (registration: RegistrationDto): Date | undefined => {
    return (
      registration.endDate ||
      registration.animal?.deathDate ||
      registration.animal?.status?.country.date
    )
  }

  const colBreed: GridColDef = {
    field: 'breed',
    headerName: `${t('animalsTable.column.breedName')}`,
    headerClassName: 'breedHeader', 
    cellClassName: 'breedCell',
    valueGetter: (params) => breedName(params.row.animal?.breedCode),
    sortComparator: (v1, v2) =>  v1.localeCompare(v2, i18n.language),
    editable: false,
    flex: 0.75,
    minWidth: 100,
  }

  const colName: GridColDef = {
    field: 'name',
    headerName: `${t('animalsTable.column.name')}`,
    headerClassName: 'nameHeader', 
    cellClassName: 'nameCell',
    valueGetter: (params) => params.row.animal?.name,
    sortComparator: (v1, v2) =>  v1.localeCompare(v2, i18n.language),
    editable: false,
    flex: 0.75,
    minWidth: 100,
  }

  const colDate: GridColDef = {
    field: 'date',
    type: 'date',
    headerName: `${t('animalsTable.column.birthDate')}`,
    headerClassName: 'dateHeader', 
    cellClassName: 'dateCell',
    valueGetter: (params) => params.row.animal?.birthDate,
    renderCell: (params) =>
      params.row.animal?.birthDate
        ? formatDate(params.row.animal?.birthDate as Date, DATE_FORMAT_FI)
        : '',
    editable: false,
    flex: 0.75,
    minWidth: 100,
  }

  const colChip: GridColDef = {
    field: 'chip',
    headerName: `${t('animalsTable.column.chipCode')}`,
    headerClassName: 'chipHeader', 
    cellClassName: 'chipCell',
    valueGetter: (params) => params.row.microchip?.microchipNumber,
    editable: false,
    flex: 0.75,
    minWidth: 100,
  }

  const colStatus: GridColDef = {
    field: 'status',
    headerName: `${t('animalsTable.column.status')}`,
    headerClassName: 'statusHeader', 
    cellClassName: 'statusCell',
    valueGetter: (params) => getStatus(params.row),
    renderCell: (params) => getStatus(params.row),
    editable: false,
    flex: 0.75,
    minWidth: 100,
  }

  const colMoreInfo: GridColDef = {
    field: 'moreInfo',
    headerName: `${t('animalsTable.column.additionalInformation')}`,
    headerClassName: 'moreInfoHeader', 
    cellClassName: 'moreInfoCell',
    sortable: false,
    renderCell: (params: any) => (
      <IconButton
        color="primary"
        onClick={() =>
          navigate(
            possessorUid
              ? `possessor/${possessorUid}/registration/${params.row.uid}`
              : `registration/${params.row.uid}`,
          )
        }
        aria-label={t('animalsTable.column.additionalInformationAria') as string}
        data-qa="registration-details-button"
        className="additionalInformationCell"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    ),
    editable: false,
    disableColumnMenu: true,
    flex: 0.75,
    minWidth: 100,
  }

  const colEndDate: GridColDef = {
    field: 'endDate',
    type: 'date',
    headerName: `${t('animalsTable.column.registrationEndDate')}`,
    headerClassName: 'endDateHeader', 
    cellClassName: 'endDateCell',
    valueGetter: (params) => getEndDate(params.row),
    renderCell: (params) =>
      getEndDate(params.row) ? formatDate(getEndDate(params.row) as Date, DATE_FORMAT_FI) : '',
    editable: false,
    flex: 0.75,
    minWidth: 100,
  }

  const currentColumns: GridColDef[] = [colBreed, colName, colDate, colChip, colStatus, colMoreInfo]

  const pastColumns: GridColDef[] = [colBreed, colName, colDate, colChip, colStatus, colEndDate]

  function getCurrent(registrations: RegistrationDto[]): RegistrationDto[] {
    return registrations.filter(
      (r) =>
        !r.endDate &&
        !r.animal?.deathDate &&
        r.animal?.status?.country.type != AnimalCountryType.Abroad,
    )
  }

  function getPast(registrations: RegistrationDto[]): RegistrationDto[] {
    return registrations.filter(
      (r) =>
        !!r.endDate ||
        !!r.animal?.deathDate ||
        r.animal?.status?.country.type == AnimalCountryType.Abroad,
    )
  }

  const currentRegistrations = getCurrent(registrations)
  const pastRegistrations = getPast(registrations)

  return (
    <SpacerBox component="section" gap={4}>
      <Box data-qa="registrations-table">
        <Typography  data-qa="current-registrations-title" variant={headerSize} mb={1}>
          {t('registrationSearchPage.animalsSection.title')}
        </Typography>
        <DataGrid
          rows={currentRegistrations}
          columns={currentColumns}
          localeText={locales()}
          getRowId={(row: any) => row.uid}
          initialState={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
          disableRowSelectionOnClick
          disableDensitySelector
          disableColumnSelector
        />
      </Box>
      {currentRegistrations && <RegistrationsDownload registrations={currentRegistrations} isCurrent={true} />}
      {pastRegistrations.length >= 1 && (
        <Box data-qa="past-registrations-table">
          <Typography  data-qa="past-registrations-title" variant="h3" mb={1}>
            {t('registrationSearchPage.animalsSection.pastRegistrationsTitle')}
          </Typography>
          <DataGrid
            rows={pastRegistrations}
            columns={pastColumns}
            localeText={locales()}
            getRowId={(row: any) => row.uid}
            initialState={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
            disableRowSelectionOnClick
            disableDensitySelector
            disableColumnSelector
          />
        </Box>
      )}
      {pastRegistrations && <RegistrationsDownload registrations={pastRegistrations} isCurrent={false} />}
    </SpacerBox>
  )
}

export default RegistrationsTable
